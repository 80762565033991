import translate from "@src/i18n"
import { Button, Input } from "antd"
import cx from "classnames"
import React from "react"

import styles from "./style.module.less"

const { TextArea } = Input

const AccountDescription = () => {
  return (
    <div className={cx("accountDescriptionContext", styles.mainContainer)}>
      <div className="tc">
        <div className={styles.form_title}>{translate("descriptionTitle")}</div>
        <div>
          <label className={cx(styles.form_subtitle)}>
            {translate("descriptionTitleSub")}
          </label>
        </div>
      </div>

      <div className={styles.container}>
        <TextArea rows={4} className="description-area" />
      </div>

      <Button type="primary" className={styles.button}>
        {translate("save")}
      </Button>
    </div>
  )
}

export default AccountDescription
