import "cropperjs/dist/cropper.css"

import { CustomModalErrors } from "@components/CustomModalErrors"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { Button, Modal, Upload } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useRef, useState } from "react"
import Cropper from "react-cropper"

import styles from "./style.module.less"

const ChangeCoverPhoto = ({
  visible,
  setVisible,
  srcCoverProfile,
  data,
  handleGetDataById,
  setSuccessMessage,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [cropper, setCropper] = useState()
  const [photo, setPhoto] = useState()
  const [file, setFile] = useState([null])
  const { affiliated, upload } = useStores()
  const { editProfileCreator } = affiliated
  const { getSignedUrlS3, uploadToS3 } = upload
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const uploadRef = useRef(null)

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreviewPhoto = async (ev) => {
    if (ev) {
      setFile(ev.file.originFileObj)
      const photoBase64 = await getBase64(ev.file.originFileObj)
      setPhoto(photoBase64)
    }
  }

  const handleEditCreator = async () => {
    setLoading(true)

    let filePathProfileCover = data.urlCoverPage

    if (file) {
      let croppedImage = null

      if (typeof cropper !== "undefined") {
        cropper.getCroppedCanvas().toBlob((blob) => {
          blob.lastModifiedDate = new Date()
          blob.name = file.name
          croppedImage = blob
        })
      }

      const paramsProfilePhoto = {
        mediaPath: "images",
        s3Path: "affiliated",
        fileName: file.name,
      }

      const signedUrlPhotoProfile = await getSignedUrlS3(paramsProfilePhoto)

      if (!signedUrlPhotoProfile) {
        setLoading(false)
        return
      }

      const { url, filePath } = signedUrlPhotoProfile
      const uploadToS3bannerRes = await uploadToS3(url, croppedImage || file)

      if (!uploadToS3bannerRes) {
        setLoading(false)
        return
      }

      filePathProfileCover = filePath
    }

    const body = {
      urlCoverPage: filePathProfileCover,
    }

    const response = await editProfileCreator(data.id, body)
    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setVisible(false)
    setLoading(false)
    setPhoto(null)
    handleGetDataById()
    setSuccessMessage(translate("changeCoverSuccess"))
  }

  const handleCancel = () => {
    setVisible(false)
    setPhoto(null)
  }

  return (
    <Modal
      visible={visible}
      className="anonimatta-modal-cover"
      footer={false}
      onCancel={() => handleCancel()}
      closable={false}
      maskClosable={true}
    >
      <TranslateWrapper>
        <div className={styles.mainContainer}>
          {
            <div>
              <div className={cx(styles.form_title)}>
                {translate("changeCoverDescription")}
              </div>
              <label className={styles.form_subtitle}>
                {translate("changeCoverDescriptionSub")}
              </label>
            </div>
          }
          <div className="anonnimatta-croppper-cover">
            <Cropper
              className="anonimatta-cropper"
              initialAspectRatio={1}
              src={!photo ? srcCoverProfile : photo}
              viewMode={1}
              guides={true}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={true}
              responsive={true}
              autoCropArea={1}
              checkOrientation={true}
              onChange={handlePreviewPhoto}
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              loading={loading}
              ref={cropper}
            />
          </div>
          <div className={cx("", styles.uplodadContainer)}>
            <Upload
              className={styles.uplodadContainer}
              ref={uploadRef}
              showUploadList={false}
              onChange={handlePreviewPhoto}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess("ok")
                }, 0)
              }}
            >
              {!photo ? (
                <Button
                  type="primary"
                  className={cx("button-cropper", styles.button)}
                >
                  {translate("upload")}
                </Button>
              ) : (
                <></>
              )}
            </Upload>

            {!photo ? (
              <></>
            ) : (
              <Button
                type="primary"
                className={cx("button-cropper", styles.button)}
                onClick={handleEditCreator}
                loading={loading}
              >
                {translate("save")}
              </Button>
            )}
          </div>
          <div className={styles.linkCancel}>
            {errors.length ? (
              <CustomModalErrors errors={errors} setErrors={setErrors} />
            ) : null}
          </div>
        </div>
      </TranslateWrapper>
    </Modal>
  )
}

export default ChangeCoverPhoto
