import { UserOutlined } from "@ant-design/icons"
import AccountDescription from "@components/accountDescription"
import ChangeCover from "@components/changeCover"
import ChangePhone from "@components/changePhone"
import ChangeProfilePhoto from "@components/changePhoto"
import { Icon } from "@components/Icons/index"
import MyAccountModal from "@components/myAccountModal"
import SocialNetworkAccount from "@components/socialNetworkAccount"
import { SuccessModal } from "@components/SuccessModal"
import TranslateWrapper from "@components/TranslateWrapper"
import { useStores } from "@hooks/use-stores"
import ChangePassword from "@modules/Auth/Site/components/ChangePasswordForm"
import ManagePublicEmail from "@modules/MyAccount/ManagePublicEmail"
import translate from "@src/i18n"
import { normalizePath } from "@utils/path"
import { getWatermarkURL } from "@utils/path"
import { maskPhone } from "@utils/strings"
import { Avatar, Card, Col, Row } from "antd"
import cx from "classnames"
import { navigate } from "gatsby"
import _get from "lodash/get"
import _map from "lodash/map"
import _uniqBy from "lodash/uniqBy"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { useMedia } from "react-use-media"

import styles from "./style.module.less"

const AccountModelPhotographer = () => {
  const [visible, setVisible] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [changePhotoVisible, setChangePhotoVisible] = useState(false)
  const [changeCoverVisible, setChangeCoverVisible] = useState(false)
  const [isClient, setIsClient] = useState(false)
  const [dataContact, setDataContact] = useState(null)
  const [newPhone, setNewPhone] = useState(null)
  const [successMessagePhoneAndPassword, setSuccessMessagePhoneAndPassword] =
    useState(null)
  const [successMessageAll, setSuccessMessageAll] = useState(null)
  const [areaCode, setAreaCode] = useState([])
  const [countries, setCountries] = useState([])

  const { authSite, profile, registrationAffiliated } = useStores()

  const isDesktop = useMedia({ minWidth: 1024 })
  const isMobileMini = useMedia({ maxWidth: 320 })
  const isMobileM = useMedia({ maxWidth: 375 })
  const isTablet = useMedia({ minWidth: 768, maxWidth: 1024 })
  const isDesktopMini = useMedia({ minWidth: 1024, maxWidth: 1365 })
  const isMobile = useMedia({ maxWidth: 767 })

  const { logout, user, myAccount } = authSite
  const { getContactById } = profile

  const { getAllCountriesAreaCode } = registrationAffiliated

  const terms = process.env.AWS_TERMS
  const privacy = process.env.AWS_PRIVACY

  const data = {
    id: _get(dataContact, "id", ""),
    urlProfile: _get(dataContact, "urlProfile", ""),
    urlCoverPage: _get(dataContact, "urlCoverPage", ""),
    name: _get(dataContact, "name", ""),
    nickname: _get(dataContact, "nickname", ""),
    twitter: _get(dataContact, "twitter", ""),
    instagram: _get(dataContact, "instagram", ""),
    phoneNumber: _get(dataContact, "phoneNumber", ""),
    publicEmail: _get(dataContact, "publicEmail", ""),
    describe: _get(dataContact, "describe", ""),
    visibleWhatsapp: _get(dataContact, "visibleWhatsapp", ""),
    username: _get(dataContact, "username", ""),
  }

  // const srcProfile = getWatermarkURL(data.urlProfile, user)
  const srcProfile = `${process.env.S3_IMAGES}/${data.urlProfile}`
  const srcCoverProfile = `${process.env.S3_IMAGES}/${data.urlCoverPage}`
  // const srcCoverProfile = getWatermarkURL(data.urlCoverPage, user)

  useEffect(() => {
    myAccount()
    handleGetDataById()
    retrieveAllCountriesAreaCode()
    setIsClient(true)
  }, [])

  useEffect(() => {
    handlePhoneNumber()
  }, [data])

  const handleClose = () => {
    setModalContent(null)
    setVisible(false)
  }

  const handleGetDataById = async () => {
    const { user } = JSON.parse(localStorage.getItem("authSite"))
    const id = _get(user, "id", "")

    const response = await getContactById(id)
    setDataContact(response)
  }

  const retrieveAllCountriesAreaCode = async () => {
    const response = await getAllCountriesAreaCode()
    const arrayDataAreaCode = []
    const arrayDataCountries = []

    _map(response, (item) => {
      const dataAreaCode = { id: item.id, code: item.areaCode }
      const dataCountries = { id: item.id, name: item.nameCountry }

      arrayDataAreaCode.push(dataAreaCode)
      arrayDataCountries.push(dataCountries)
    })

    const uniqByAreaCode = _uniqBy(arrayDataAreaCode, "code")
    uniqByAreaCode.sort((a, b) => {
      return a.code - b.code
    })

    setAreaCode(uniqByAreaCode)
    setCountries(arrayDataCountries)
  }

  const handlePhoneNumber = () => {
    if (data.phoneNumber.length === 12) {
      const phone = data.phoneNumber.slice(1)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 13) {
      const phone = data.phoneNumber.slice(2)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 14) {
      const phone = data.phoneNumber.slice(3)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
    if (data.phoneNumber.length === 15) {
      const phone = data.phoneNumber.slice(4)
      const newPhone = maskPhone(phone)
      setNewPhone(newPhone)
    }
  }

  const handleClickLogout = () => logout()

  const pageUri = normalizePath(`/perfil/${data?.username}`)
  const handleNameClicked = (e) => {
    e.preventDefault()
    navigate(pageUri)
  }

  // const handleClickProfile = () => {
  //   const pageUri = normalizePath(`/perfil/${dataContact.username}`)
  //   navigate(pageUri)
  // }

  if (!isClient) return null

  const switchRender = (option) => {
    setVisible(true)

    switch (option) {
      case "password":
        setModalContent(
          <ChangePassword
            handleClose={handleClose}
            setModalContent={setModalContent}
            setSuccessMessage={setSuccessMessagePhoneAndPassword}
            data={data}
            areaCode={areaCode}
          />
        )
        return

      case "phone":
        setModalContent(
          <ChangePhone
            handleClose={handleClose}
            setModalContent={setModalContent}
            data={data}
            setVisible={setVisible}
            setSuccessMessage={setSuccessMessagePhoneAndPassword}
            areaCode={areaCode}
            countries={countries}
          />
        )
        return

      // case "language":
      //   setModalContent(<ChangeLanguage />)
      //   return

      case "socials":
        setModalContent(
          <SocialNetworkAccount
            data={data}
            handleClose={handleClose}
            handleGetDataById={handleGetDataById}
            setSuccessMessage={setSuccessMessageAll}
          />
        )
        return

      case "name":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataById={handleGetDataById}
            setSuccessMessage={setSuccessMessageAll}
            isName
          />
        )
        return

      case "nickname":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataById={handleGetDataById}
            setSuccessMessage={setSuccessMessageAll}
            isNickname
          />
        )
        return

      case "email":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataById={handleGetDataById}
            setSuccessMessage={setSuccessMessageAll}
            isEmail
          />
        )
        return

      case "coverPhoto":
        setVisible(false)
        setChangeCoverVisible(true)
        return

      case "description":
        setModalContent(
          <ManagePublicEmail
            data={data}
            handleClose={handleClose}
            handleGetDataById={handleGetDataById}
            setSuccessMessage={setSuccessMessageAll}
            isDescription
          />
        )
        return

      default:
        return
    }
  }

  return (
    <>
      <TranslateWrapper>
        <Helmet>
          <title>Minha Conta (Criador) - Anonimatta</title>
        </Helmet>
        <div className="page">
          <div className={cx("mc center creatorAccount", styles.contentPage)}>
            <div className={styles.containerSubscriber}>
              <div className={styles.profileContainer}>
                <div className={cx("flex", styles.profileCoverImage)}>
                  <div
                    className={styles.coverImageContainer}
                    style={{
                      width: "100%",
                      backgroundColor: "#ebebeb",
                      backgroundImage: `url("${srcCoverProfile}")`,
                      backgroundSize: "cover",
                    }}
                  >
                    <button
                      className={styles.changeCoverImage}
                      onClick={() => switchRender("coverPhoto")}
                    >
                      <img
                        className="pointer"
                        src={require("@images/icons/cameraCover.svg")}
                        width={isDesktop || isTablet ? "20px" : "15px"}
                        height={isDesktop || isTablet ? "20px" : "15px"}
                        alt="Alterar capa"
                      />
                      {isMobile ? (
                        <span>Alterar</span>
                      ) : (
                        <span>Alterar foto de capa</span>
                      )}
                    </button>

                    <div
                      className={styles.avatarSize}
                      style={{
                        backgroundImage: `url("${srcProfile}")`,
                      }}
                    >
                      <button
                        className={styles.newPhoto}
                        onClick={() => setChangePhotoVisible(true)}
                      >
                        <img
                          className="pointer"
                          src={require("@images/icons/camera.svg")}
                          /*  width={isDesktop || isTablet? "24px":   "18.53px"}
                            height={isDesktop || isTablet? "30px":   "16.68px"} */
                          alt="Alterar foto"
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <a onClick={handleNameClicked} className="pointer">
                  <div className={styles.profileName}>
                    <label>
                      {data.name}
                      <img src={require("@images/external-link.svg")} />
                    </label>
                  </div>
                </a>
              </div>

              {/* <div
                      className={cx(
                        "flex flex-column mt8 ml12",
                        styles.mobilePhoto
                      )}
                    >
                      <label
                        className={cx(
                          "f20 fw5 tc lh-copy mine-shaft pointer",
                          styles.name
                        )}
                        onClick={() => handleClickProfile()}
                      >
                        {data.name}
                      </label>
                      <label
                        className={cx(
                          "f14 fw5 tc lh-copy french-rose pointer",
                          styles.newPhoto
                        )}
                        onClick={() => setChangePhotoVisible(true)}
                      >
                        {translate("changePhoto")}
                      </label>
                    </div> */}

              <Row
                gutter={[isMobile ? 0 : 36, isMobileMini ? 0 : 24]}
                className={styles.containerCards}
              >
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className={styles.sepatorCard}></div>
                  <div className={cx(styles.gridWidth)}>
                    <Card
                      className={cx(
                        "anonimatta-card-criator",
                        styles.colorCard
                      )}
                    >
                      <div
                        className={cx(
                          "flex items-center",
                          styles.marginCardTitle
                        )}
                      >
                        <img
                          className="pointer"
                          src={require("@images/icons/my-account.svg")}
                          width="24px"
                          height="24px"
                          alt="Minha conta"
                        />

                        <label className={cx("", styles.accountLabel)}>
                          {translate("myAccount")}
                        </label>
                      </div>

                      <div className={styles.contentCard}>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("password")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("password")}
                            </label>
                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              *********
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("phone")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("phone")}
                            </label>

                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              {newPhone}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>

                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("name")}
                        >
                          <div className={styles.containerLabels}>
                            <label
                              className={cx(
                                "f16 fw5 lh-copy mine-shaft pointer",
                                styles.labelCard
                              )}
                            >
                              {translate("name")}
                            </label>
                            <label
                              className={cx(
                                "f12 fw5 tr lh-copy mr12 pointer",
                                styles.labelCardInfo
                              )}
                            >
                              {data?.name || ""}
                            </label>
                          </div>

                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                        <div
                          className={styles.sectionContainer}
                          /* onClick={() => switchRender("username")}  */
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("username")}
                            </label>
                            <label
                              className={
                                /*  data.username === "" ||  data.username === null
                                    ? styles.labelInfoEmpty
                                    : */ styles.labelCardInfo
                              }
                            >
                              {data?.username || ""}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <div className={styles.sepatorCard}></div>

                  <div className={cx(styles.gridWidth)}>
                    <Card
                      className={cx(
                        "anonimatta-card-criator",
                        styles.colorCard
                      )}
                    >
                      <div
                        className={cx(
                          "flex items-center",
                          styles.marginCardTitle
                        )}
                      >
                        <img
                          name="CreditCard"
                          className="pointer"
                          src={require("@images/icons/public-data.svg")}
                          width="24px"
                          height="24px"
                          alt="Minha conta"
                        />

                        <label className={cx("", styles.accountLabel)}>
                          {translate("publicData")}
                        </label>
                      </div>
                      <div className={styles.contentCard}>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("nickname")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("nickname")}
                            </label>

                            <label
                              className={
                                /* data.nickname   === ""
                                    ? styles.labelInfoEmpty
                                    : */ styles.labelCardInfo
                              }
                            >
                              {data?.nickname || ""}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>

                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("email")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("switchEmail")}
                            </label>
                            <label className={cx(styles.labelCardInfo)}>
                              {data.publicEmail}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>

                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("socials")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("socialNetworks")}
                            </label>
                            <label className={cx(styles.labelCardInfo)}>
                              {translate("instagram")}, {translate("twitter")}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                        <div
                          className={styles.sectionContainer}
                          onClick={() => switchRender("description")}
                        >
                          <div className={styles.containerLabels}>
                            <label className={cx(styles.labelCard)}>
                              {translate("descriptionUser")}
                            </label>
                            <label className={cx(styles.labelCardInfo)}>
                              {data.describe}
                            </label>
                          </div>
                          <Icon name="Down" className={styles.iconDown} />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
              </Row>

              <Row gutter={[isMobile ? 0 : 36, isMobileMini ? 0 : 24]}>
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  className={styles.containerTerms}
                >
                  <div className={styles.margin}>
                    <div className={styles.sepatorCard}></div>
                    <div className={styles.cardTerms}>
                      <div className={styles.termslinks}>
                        <img
                          className={styles.icon}
                          src={require("@images/icons/document.svg")}
                          width="14px"
                          height="14px"
                          alt="Plano"
                        />
                        <a
                          href={terms}
                          className={styles.termsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {translate("term")}
                        </a>
                        <span
                          style={{ marginLeft: 3, marginRight: 3 }}
                          className={styles.terms}
                        >
                          {translate("and")}
                        </span>
                        <a
                          href={privacy}
                          className={styles.termsLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {translate("privacy")}
                        </a>
                      </div>
                      <span className={styles.separatorTerms}></span>

                      <div
                        className={styles.logout}
                        onClick={handleClickLogout}
                      >
                        <img
                          className={styles.icon}
                          src={require("@images/icons/logout.svg")}
                          width="14px"
                          height="14px"
                          alt="Plano"
                        />{" "}
                        Sair do aplicativo{" "}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </TranslateWrapper>

      <MyAccountModal
        visible={visible}
        handleClose={handleClose}
        className="mc forgotPassword"
      >
        <TranslateWrapper>{modalContent}</TranslateWrapper>
      </MyAccountModal>

      <ChangeProfilePhoto
        visible={changePhotoVisible}
        setVisible={setChangePhotoVisible}
        srcProfile={srcProfile}
        data={data}
        handleGetDataById={handleGetDataById}
        setSuccessMessage={setSuccessMessageAll}
      />

      <ChangeCover
        visible={changeCoverVisible}
        setVisible={setChangeCoverVisible}
        srcCoverProfile={srcCoverProfile}
        data={data}
        handleGetDataById={handleGetDataById}
        setSuccessMessage={setSuccessMessageAll}
      />
      {successMessagePhoneAndPassword ? (
        <SuccessModal
          visible={!!successMessagePhoneAndPassword}
          description={successMessagePhoneAndPassword}
          handleClose={() => {
            setSuccessMessagePhoneAndPassword(null)
            logout()
          }}
          buttonText={translate("continue")}
        />
      ) : null}
      {successMessageAll ? (
        <SuccessModal
          visible={!!successMessageAll}
          description={successMessageAll}
          handleClose={() => setSuccessMessageAll(null)}
          buttonText={translate("continue")}
        />
      ) : null}
    </>
  )
}

export default AccountModelPhotographer
