import { CustomModalErrors } from "@components/CustomModalErrors"
import { Icon } from "@components/Icons"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { Button, Checkbox, Col, Form, Input, Row } from "antd"
import _get from "lodash/get"
import React, { useState } from "react"
import cx from "classnames"

import styles from "./style.module.less"

const SocialNetworkAccount = ({
  data,
  handleClose,
  handleGetDataById,
  setSuccessMessage,
}) => {
  const { affiliated } = useStores()
  const { editProfileCreator } = affiliated
  const [errors, setErrors] = useState([])
  const [creatorInstagram, setCreatorInstagram] = useState(instagram)
  const [creatorTwitter, setCreatorTwitter] = useState(twitter)
  const [loading, setLoading] = useState(false)
  const [whatsapp, setWhatsapp] = useState(false)

  const { id, twitter, instagram, visibleWhatsapp } = data

  const handleChangeInstagram = (event) => {
    const value = event.target.value
    setCreatorInstagram(value)
  }

  const handleChangeTwitter = (event) => {
    const value = event.target.value
    setCreatorTwitter(value)
  }

  const handleCheck = (event) => {
    const value = event.target.checked
    setWhatsapp(value)
  }

  const handleEditCreator = async () => {
    setLoading(true)
    data.instagram = creatorInstagram
    data.twitter = creatorTwitter
    data.visibleWhatsapp = whatsapp

    const response = await editProfileCreator(id, data)

    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setLoading(false)
    handleClose()
    handleGetDataById()
    setSuccessMessage(translate("socialNetworksChangeSuccess"))
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.form_title}>{translate("socialNetworks")}</div>

      <label className={styles.form_subtitle}>
        {translate("linkMessage")}{" "}
      </label>

      <Form
        className={styles.form_container}
        name="basic"
        initialValues={{
          instagram: instagram,
          twitter: twitter,
          visibleWhatsapp: visibleWhatsapp,
        }}
      >
        <div className={cx("socialForm", styles.container)}>
          <div className={styles.marginFormItem}>
            <span className={styles.form_label}> {translate("instagram")}</span>
            <Form.Item name="instagram" className="form-item-social">
              <Input
                style={{ fontFamily: "Poppins" }}
                /* prefix={
                  creatorInstagram ? (
                    <Icon
                      name="InstagramInactive"
                      className={styles.socialActiveIcon}
                    />
                  ) : (
                    <Icon name="InstagramInactive" />
                  )
                } */
                className="social-input"
                onChange={(event) => handleChangeInstagram(event)}
              />
            </Form.Item>
          </div>
          <div>
            <span className={styles.form_label}>{translate("twitter")}</span>
            <Form.Item name="twitter" className="form-item-social">
              <Input
                /* prefix={
                  creatorTwitter ? (
                    <Icon
                      name="TwitterActive"
                      className={styles.socialActiveIcon}
                    />
                  ) : (
                    <Icon name="TwitterInactive" />
                  )
                } */
                className="social-input"
                onChange={(event) => handleChangeTwitter(event)}
              />
            </Form.Item>
          </div>

          {/*   <Row className="mt16">
            <Col>
              <Form.Item name="visibleWhatsapp" valuePropName="checked">
                <Checkbox className={styles.checkBox} onChange={handleCheck}>
                  <span>{translate("phoneProfile")}</span>
                </Checkbox>
              </Form.Item>
            </Col>
          </Row> */}
        </div>

        <Button
          type="primary"
          className={styles.button}
          onClick={() => handleEditCreator()}
          loading={loading}
        >
          {translate("buttonSave")}
        </Button>
      </Form>

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </div>
  )
}

export default SocialNetworkAccount
